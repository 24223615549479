@use "/src/styles/variables" as *;

.footer {
  font-family: var(--font);
  font-style: normal;
  padding-top: 3rem;
  background-color: var(--dark);
  color: var(--white);
  font-weight: 300;

  h2 {
    font-size: 2rem;
    margin: 1rem 0;
  }

  p {
    font-size: 1rem;
    margin: 1rem 0 2rem 0;
  }

  &__container {
    padding: 0 calc((100vw - min($l-screen, 100vw - $padding*2)) / 2);
    margin-bottom: 3rem;
    max-width: var(--large-screen);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0;
    align-items: stretch;

    @media screen and (max-width: $m-screen) {
      flex-direction: column;
      gap: 0;
    }
  }

  &__col:first-child {
    padding-right: 4rem;
  }

  &__col {
    max-width: 440px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__img {
    width: 5rem;
    margin: 1rem 0;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;

    @media screen and (max-width: $s-screen){
      flex-direction: column;

    }
  }

  /* Contact */


  form {
  }

  label {
    margin: 0.5rem 0;
    color: var(--primary);
  }

  label {

  }

  input, textarea {
    height: 2rem;
    font-size: 1rem;
    font-weight: 300;
    font-family: var(--font);
    margin-bottom: 1rem;
    line-height: 1.5rem;
    padding: 5px 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 0.5px solid var(--grey);
    color: var(--white);
    background-color: rgba(255,255,255,0.1);
  }

  textarea {
    min-height: 6rem ;
  }

  input::placeholder{
    color: var(--white);
  }

  textarea::placeholder{
    color: var(--white);
  }


  /* Footer */

  &__footer {
    max-width: var(--large-screen);
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 2rem 2rem;
    border-top: 2px solid #f0f0f0;
    font-size: 1rem;
    align-items: center;
    span, a {
      width: auto;
      white-space: nowrap;
    }

    a:hover {
      text-decoration: underline;
    }

  }

  &__filler {
    flex: 1;
  }

  &__social {

  }

}
