@use '/src/styles/variables.scss' as *;

.JoinUsSection {
  background-color: var(--blue);

  .swiper-slide {
    height: 25rem;
    @media screen and (max-width: $s-screen){
      height: 30rem;
    }
    width: 100vw;
    background-color: var(--blue);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    canvas {
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: -1;
    }

    h2 {
      color: var(--white);
      font-size: 3rem;
      padding: 0.5rem;
      font-weight: 300;
    }

    p {
      font-weight: 300;
      padding: 0.5rem;
      color: var(--white);
      font-size: 1.5rem;
      line-height: 1.75rem;
      text-align: center;
      white-space: pre-wrap;
    }

    button {
      margin: 1rem;
    }

    .big-button {
      margin: 2rem;
    }
  }

  &__Pagination {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    color: var(--white);
    bottom: 2rem;

    &__Button {
      cursor: pointer;
    }

    &__Button-disabled {
      cursor: not-allowed;
      opacity: 30%;
    }

  }

  .button {
    transition: background-color,color,border 0.3s;
  }

  &__Good {
    background-color: var(--red) !important;
    border-color: var(--red) !important;
    color: var(--white) !important;
  }

  &__Bad {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    background-color: var(--dark) !important;
    border-color: var(--dark) !important;
    color: var(--white) !important;
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }

}


