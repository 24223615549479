@use '/src/styles/variables.scss' as *;

.Navbar {
  /* Menu */
  position: fixed;
  transition: height 0.5s;
  width: 100vw;
  height: 6rem;
  background-color: var(--white);
  font-family: var(--font);
  border-bottom: 1px solid var(--dark);
  font-size: 1.3rem;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;

  @media screen and (max-width: $s-screen){
    align-items: start;
    padding: 1rem;
    justify-content: start;
    overflow: hidden;
  }

  /* Logo */

  &__logo {
    transition: 0.3s;
    overflow: hidden;
    height: 3rem;
    img {
      height: 100%;
      width: auto;
      max-width: 200px;
    }

    @media screen and (max-width: $s-screen){
      position: absolute;
      top: 1rem;
      left: 50%;
      transform: translateX(-50%);
    }

  }

  /* Marker */

  &__marker-progress {
    z-index: 100;
    display: block;
    position: fixed;
    top: 6rem;
    width: 100vw;
    height: 20px;

    @media screen and (max-width: $s-screen){
      display: none;
    }

  }
  &__marker-progress-dot {
    position: absolute;
    top: -0.5rem;
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    transition: 0.3s linear;
    background-color: var(--dark);
  }

  /* Links */

  &__links {
    position: relative;

    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;

    overflow: hidden;

    color: var(--grey);
    width: 70%;

    @media screen and (max-width: $s-screen) {
      justify-content: start;
      flex-direction: column;
      width: 100%;
      align-items: stretch;
      gap: 2rem;
      text-align: center;
      padding: 6rem 1rem;
    }
  }

  &__link {
    position: relative;
    display: inline;

    &--activated {
      color: var(--dark);
    }

    &__blog {
      color: var(--red);
    }

    &__contact {
      color: #ffffff;
      background: var(--red);
      padding: 0.5rem 1rem;
      transition: background 0.3s;
      cursor: pointer;
      font-size: 1.3rem;


      &:hover {
        background-color: #ffffff;
        outline: none;
        a {
          color: var(--primary);
          transition: color 0.3s;
        }
      }
    }
    :hover {
      color: var(--dark);
      transition: 0.7s;
      text-decoration: none;

    }
  }



  &__animation {
    &--blog {
      &:hover {
        color: var(--primary);
      }
      &::after {
        // animation soulignement pour le lien blog
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        transform: translateY(-2px) scale(0);
        background-color: var(--primary);
        transition: transform 0.3s ease-in-out;
      }
      &:hover::after {
        transform: translateY(-2px) scale(1);
      }
    }
    &::after {
      // animation soulignement pour tous
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
      transform: translateY(-2px) scale(0);
      background-color: var(--dark);
      transition: transform 0.3s ease-in-out;
    }
    &:hover::after {
      transform: translateY(-2px) scale(1);
    }
  }

  /* Burger */

  &__hamburger {


    width: 40px;
    height: 40px;
    display: block;
    float: right;
    transform: rotate(0deg) translateY(0.5rem);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    position: absolute;
    z-index: 100;

    @media only screen and (min-width: $s-screen) {
      display: none;
    }

    &--animation {
      span:nth-child(1) {
        transform: rotate(43deg)translate(12px,12px);
      }
      span:nth-child(2) {
        opacity: 0;
      }
      span:nth-child(3) {
        transform: rotate(-43deg)translate(5px,-5px);
      }
    }
  }
  &__hamburger span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: white;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  &__hamburger span:nth-child(1) {
    top: 0;
    background-color: var(--tertiary);
  }
  &__hamburger span:nth-child(2) {
    top: 12px;
    background-color: var(--primary);
  }
  &__hamburger span:nth-child(3) {
    top: 24px;
    background-color: var(--quaternary);
  }
  &__hamburger.open span:nth-child(1) {
    top: 14px;
    transform: rotate(135deg);
  }
  &__hamburger.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
  &__hamburger.open span:nth-child(3) {
    top: 14px;
    transform: rotate(-135deg);
  }
}

@keyframes moveDown {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0rem);
  }
}


.Navbar.Navbar__responsive {
  height: 90vh;

  &__responsive-container {
    display: flex;
    flex-direction: column;
  }
  &__responsive-menu {
    position: absolute;
    top: 38px;
    right: 50px;
  }
  &__responsive-links {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    height: 100%;
  }
}